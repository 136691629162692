<template>
  <div class="empty_box" :style="{ height: hVal, }">
    <div class="empty_img">
      <img src="@/assets/images/empty.png" alt="" />
    </div>
    <div class="empty_name">{{TextVal}}</div>
  </div>
</template>

<script>
export default {
  props: {
    hVal: {
      type: String,
      default: "200px",
    },
    TextVal: {
      type: String,
      default: "暂无内容",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.empty_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty_img {
  width: 100px;
  height: 100px;
  > img {
    width: 100px;
    height: 100px;
  }
}
.empty_name {
  font-size: 16px;
  line-height: 50px;
  color: #999;
}
</style>